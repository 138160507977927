<template>
  <div id="downloadcomputer">
    <svg-icon name="windowDownload" class="window" size="140"/>
    <svg-icon name="macDownload" class="mac" size="140"/>
  </div>
</template>

<script>
import SvgIcon from '../SvgIcon/SvgIcon.vue'
export default {
  name:'DownLoadComputer',
  components: {
    SvgIcon

  },
  data() {
    return {

    }
  },
  methods:{},
}
</script>

<style scoped>
#downloadcomputer{
  position: relative;
  height: 48px;
}
.mac{
  margin-left: 14px;
  margin-top: -48px;
}
.window{
  margin-top: -48px;
}
</style>
