<template>
  <img class="phone" src="@/assets/img/service/phone3.png">
  <img class="computer" src="@/assets/img/service/computer.png">
  <div id="personalthree">
    <div class="title">
      <h1>雙平台，實現隨時隨地全球交易。</h1>
      <h2>簡單 ，輕鬆 ，安全</h2>
    </div>
    <div class="content">
      <div class="boxBox">
        <div class="box">
          <h2>手機App Download</h2>
          <div class="boxContent">
            <down-load-phone></down-load-phone>
          </div>
        </div>
        <div class="box">
          <h2>Finger Tarding網頁交易</h2>
          <btn-submit color="red">Go Now</btn-submit>
        </div>
        <div class="box">
          <h2>電腦軟體 Download</h2>
          <down-load-computer></down-load-computer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DownLoadPhone from '@/components/footer/DownLoadPhone.vue'
import DownLoadComputer from '@/components/footer/DownLoadComputer.vue'
import BtnSubmit from '@/components/button/BtnSubmit.vue'
export default {
  name:'PersonalThree',
  components: {
    DownLoadPhone,
    DownLoadComputer,
    BtnSubmit
  },
  data() {
    return {

    }
  },
  methods:{},
}
</script>

<style scoped>
.phone{
  position: absolute;
  left: 20%;
  top: 200px;
  width: 400px;
}
.computer{
  position: absolute;
  bottom: 0;
  right: 8%;
  width: 700px;
}
.title{
  box-sizing: border-box;
  padding-top: 200px;
  color: aliceblue;
  text-align: center;
}
.title h1{
  font-size: 60px;
}
.content{
  display: flex;
  justify-content: center;
  width: 100%;
}
.boxBox{
  display: flex;
  justify-content: space-between;
  width: 70%;
  padding-top: 100px;
}
.box{
  position: relative;
  width: 480px;
  color: #fff;
  text-align: center;
}
h2{
  margin-bottom: 20px;
}
.boxContent{
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
